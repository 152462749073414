<template>
  <dashboard />
</template>

<script>
const Dashboard = () => import('@/components/Dashboard')

export default {
  name: 'DashboardView',
  components: {
    Dashboard
  }
}
</script>
